import React from "react"
import styled from "@emotion/styled"
import ContentModules from "../components/content-modules"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import CategoryList from "../components/category-list/category-list"
import { NewsletterPopup } from "../components/newsletter-popup"

// MAIN CONTENT LAYOUT
const MainContentContainer = styled.div`
  ${({ theme }) => theme.bp.desktop} {
    display: flex;
  }
`

const MainContentLeftColumn = styled.div`
  ${({ theme }) => theme.bp.desktop} {
    width: calc(50% - 100px);
  }
`

const MainContentLeftColumnContent = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.marginTop.header}px;
`
const MainContentRightColumn = styled(
  /**
   * Right column
   *
   * @param {{
   *   highlighted?: boolean;
   *   className?: string;
   * }} props
   */
  ({ highlighted, ...props }) => (
    <div
      {...props}
      className={(highlighted ? "isHighlighted" : "") + " " + props.className}
    />
  )
)`
  padding: 20px 0 20px 20px;
  background-color: ${(props) =>
    props.highlighted ? "#f56129" : "transparent"};

  ${({ theme }) => theme.bp.desktop} {
    width: calc(50% + 100px);
    padding-left: 30px;
    padding-right: 30px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    padding: 25px 50px;
  }
`

const MainContentRightColumnContent = styled.div``

// STORIES
const FeaturedStoriesLayout = styled.div`
  display: flex;
  padding-right: 20px;
  overflow: scroll;
  scrollbar-width: none; // hide Firefox scrollbars

  ${({ theme }) => theme.bp.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    column-gap: 6px;
    row-gap: 50px;
    padding-right: 0;
  }
`

const FeaturedStoriesLayoutItem = styled.div`
  flex-shrink: 0;
  width: 75%;
  margin-left: 6px;

  &:first-child {
    margin-left: 0;
  }

  ${({ theme }) => theme.bp.desktop} {
    width: auto;
    grid-column: ${(props) => (props.stretched ? "span 2" : "span 1")};
    margin-left: 0;
  }
`

const MoreStories = styled(Link)`
  width: 100%;
  padding-block: 12px;
  padding-inline: 20px;
  margin-left: 22px;
  margin-bottom: 52px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.darkGreen};
  border-radius: 26px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: center;
  text-decoration: none;
  writing-mode: vertical-rl;
  color: ${({ theme }) => theme.colors.darkGreen};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkGreen};
    color: ${({ theme }) => theme.colors.white};
  }

  div${MainContentRightColumn}.isHighlighted & {
    border-color: #172f20;
    color: #172f20;

    &:hover {
      background-color: #172f20;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme }) => theme.bp.desktop} {
    display: block;
    grid-column: span 2;
    margin-left: 0;
    margin-bottom: 0;
    writing-mode: horizontal-tb;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

// SECTION HEADER
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-top: ${({ theme }) => theme.borders.thin};

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const SectionHeaderTitle = styled.h4`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  line-height: 1.5;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

const SectionHeaderMore = styled(Link)`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.darkGreen};

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: 18px;
  }
`

// FEATURED PRODUCTS
const FeaturedProductsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 2px;

  ${({ theme }) => theme.bp.largeDesktop} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`

const FeaturedProductsLayoutItem = styled.div`
  &:nth-child(5):last-child {
    grid-column: 1 / span 2;
    grid-row: 3 / span 2;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    &:nth-child(5):last-child {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;

      // Large pic - stretch product card
      > a {
        display: flex;
        flex-direction: column;
        height: 100%;

        > div:first-child {
          height: 100%;

          div {
            height: 100%;
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const { frontPage } = data

  const tileSections = frontPage?.contentModules.filter(
    (m) => m.internal?.type === "ContentfulTileSection"
  )

  const leftColumn = frontPage?.contentModules.filter((m) => m.isLeftColumn)
  const rightColumn = tileSections?.find(
    (tileSection) => tileSection.isRightColumn
  )
  const featuredCategories = tileSections?.find(
    (tileSection) => tileSection.isCategories
  )

  let categories = []
  if (featuredCategories?.tiles?.length) {
    categories = featuredCategories.tiles
      .filter(
        (e) =>
          e.reference?.internal?.type &&
          e.reference.internal.type === "ContentfulCategory"
      )
      ?.map((e) => {
        return {
          ...e.reference,
          thumbnail: e.backgroundImage?.id
            ? e.backgroundImage
            : e.reference.thumbnail,
        }
      })
  }

  const recommended = tileSections?.find((m) => m.isFeaturedProducts)
  let featuredProducts = []

  if (recommended?.tiles?.length) {
    featuredProducts = recommended.tiles
      .filter(
        (p) =>
          p.reference?.internal?.type &&
          p.reference.internal.type === "ContentfulProduct"
      )
      ?.map((p) => {
        let categories = p.reference.product_group?.flatMap((pg) =>
          pg.category?.flatMap((c) => c.slug)
        )
        let category = categories?.[0] ?? "all" // NOTE: Product should be at least in one category to have a valid url slug
        let slug = p.reference.handle

        return {
          ...p.reference,
          fullSlug: p.reference.fullSlug ?? category + "/" + slug,
          primaryImage: p.backgroundImage?.id
            ? p.backgroundImage
            : p.reference.primaryImage,
          featuredImage: p.backgroundImage?.id
            ? p.backgroundImage
            : p.reference.featuredImage,
        }
      })
  }

  return (
    <>
      <SEO />
      <MainContentContainer>
        {leftColumn?.[0]?.tiles?.[0]?.reference && (
          <MainContentLeftColumn>
            <MainContentLeftColumnContent>
              <ContentModules
                allModules={[leftColumn[0].tiles[0].reference]}
                options={{ position: "leftColumn" }}
              />
            </MainContentLeftColumnContent>
          </MainContentLeftColumn>
        )}
        <MainContentRightColumn highlighted={frontPage.isOrangeBackground}>
          <MainContentRightColumnContent>
            <FeaturedStoriesLayout>
              {(rightColumn?.tiles || []).map((e, i) => {
                return e.reference?.internal?.type &&
                  e.reference.internal.type === "ContentfulStory" ? (
                  <FeaturedStoriesLayoutItem stretched={e.stretched}>
                    <ContentModules
                      allModules={[
                        {
                          ...e.reference,
                          thumbnail: e.backgroundImage?.id
                            ? e.backgroundImage
                            : e.reference.thumbnail,
                        },
                      ]}
                      options={{ position: "rightColumn" }}
                    />
                  </FeaturedStoriesLayoutItem>
                ) : null
              })}
              <MoreStories to={"/stories"}>All Stories</MoreStories>
            </FeaturedStoriesLayout>
          </MainContentRightColumnContent>
        </MainContentRightColumn>
      </MainContentContainer>

      {/* <SectionHeader>
        <SectionHeaderTitle>Shop by category</SectionHeaderTitle>
      </SectionHeader> */}

      {categories?.length ? (
        <CategoryList categories={categories} stacked></CategoryList>
      ) : null}

      {featuredProducts?.length ? (
        <>
          <SectionHeader>
            <SectionHeaderTitle>{recommended.title}</SectionHeaderTitle>
            {/*
            <SectionHeaderMore href={recommended.linkTo}>
              View all
            </SectionHeaderMore>
            */}
          </SectionHeader>
          <FeaturedProductsLayout>
            {(featuredProducts || []).map((product) => {
              return (
                <FeaturedProductsLayoutItem>
                  <ContentModules
                    allModules={[product]}
                    options={{ position: "featuredProducts" }}
                  />
                </FeaturedProductsLayoutItem>
              )
            })}
          </FeaturedProductsLayout>
        </>
      ) : null}
      <NewsletterPopup isVisible={true} />
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query IndexQuery {
    frontPage: contentfulPage(isHomepage: { eq: true }) {
      name
      isOrangeBackground
      contentModules {
        ...TileSection
      }
    }
  }
`
